import {
  AnchorRouter,
  FaceBookIcon,
  FynbosLogo,
  GoogleTag,
  InstagramIcon,
  LinkedInIcon,
  MetaPixel,
  Router,
  XIcon
} from '@/Components'
import { MarketingHeader } from '@/Layouts/MarketingHeader'
import { usePage } from '@inertiajs/react'
import { FC, ReactNode } from 'react'

type MarketingScaffoldProps = {
  children?: ReactNode
}

type Props = {
  happyPay?: boolean
}

export const MarketingLayout: FC<MarketingScaffoldProps> = ({ children }) => {
  const { happyPay } = usePage<Props>().props
  return (
    <div className='relative inset-0 flex min-h-dvh w-full flex-col'>
      {happyPay && (
        <div className='flex w-full items-center justify-center gap-4 bg-[#FFE05D] p-4'>
          <svg
            className='flex-none'
            width='43'
            height='44'
            viewBox='0 0 43 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M17.9255 12.3681C18.5327 12.7124 19.0225 13.2309 19.3316 13.8567C19.6687 14.512 19.837 15.2989 19.8364 16.2174V22.1893H17.2642V16.5649C17.2642 15.7565 17.0621 15.1347 16.6579 14.6995C16.2537 14.2644 15.7024 14.0471 15.0041 14.0476C14.2937 14.0476 13.7333 14.2649 13.3228 14.6995C12.9123 15.1341 12.707 15.7559 12.707 16.5649V22.1875H10.1348V8.59009H12.707V13.2762C13.0529 12.8234 13.5084 12.4662 14.0306 12.2382C14.6084 11.9812 15.2351 11.8524 15.8675 11.8607C16.586 11.8478 17.2954 12.0227 17.9255 12.3681Z'
              fill='#191C21'
            />
            <path
              d='M26.2226 12.3112C26.8376 11.9909 27.5229 11.8298 28.2161 11.8425C29.048 11.8319 29.8661 12.0547 30.5777 12.4858C31.2945 12.9277 31.8738 13.5609 32.2504 14.3141C32.6603 15.1042 32.8656 16.0198 32.8661 17.061C32.8667 18.1021 32.6615 19.0241 32.2504 19.8267C31.8753 20.5902 31.2968 21.2354 30.5785 21.6912C29.871 22.1335 29.0513 22.3633 28.217 22.3534C27.5295 22.3679 26.8495 22.2097 26.239 21.8933C25.6994 21.6118 25.227 21.2169 24.8544 20.7358V27.0387H22.2812V12.0076H24.8544V13.4782C25.2135 12.9881 25.682 12.5885 26.2226 12.3112ZM29.8613 15.4717C29.6313 15.0464 29.2877 14.6933 28.8689 14.4517C28.463 14.2207 28.0037 14.1003 27.5367 14.1026C27.0744 14.1016 26.6204 14.2255 26.2226 14.4612C25.8028 14.7095 25.4594 15.0687 25.2302 15.4992C24.9825 15.9957 24.8535 16.5431 24.8535 17.098C24.8535 17.6529 24.9825 18.2002 25.2302 18.6967C25.4598 19.127 25.8031 19.486 26.2226 19.7347C26.6239 19.9711 27.0814 20.0949 27.547 20.0933C28.0127 20.0916 28.4693 19.9645 28.8689 19.7253C29.2876 19.4714 29.6304 19.1096 29.8613 18.6778C30.1113 18.2243 30.2368 17.6853 30.238 17.061C30.2518 16.5076 30.1221 15.96 29.8613 15.4717Z'
              fill='#191C21'
            />
            <path
              d='M20.6184 35.4101C16.1258 35.4101 11.9513 33.5507 8.86564 30.1744C8.63756 29.9254 8.51771 29.5961 8.53247 29.2587C8.54723 28.9214 8.69538 28.6038 8.94433 28.3757C9.19329 28.1476 9.52266 28.0278 9.85998 28.0425C10.1973 28.0573 10.5149 28.2054 10.743 28.4544C13.3411 31.2975 16.849 32.8627 20.6184 32.8627C25.2959 32.8627 29.5925 30.4117 32.1114 26.3052C32.1978 26.1609 32.312 26.0351 32.4473 25.9351C32.5826 25.8351 32.7364 25.763 32.8998 25.7229C33.0632 25.6828 33.233 25.6756 33.3992 25.7015C33.5654 25.7275 33.7248 25.7861 33.8682 25.8741C34.0116 25.9621 34.1361 26.0777 34.2346 26.2141C34.333 26.3506 34.4034 26.5052 34.4416 26.669C34.4799 26.8329 34.4852 27.0027 34.4574 27.1686C34.4296 27.3345 34.3691 27.4933 34.2795 27.6357C31.2944 32.505 26.1869 35.4101 20.6184 35.4101Z'
              fill='#191C21'
            />
          </svg>
          <p className='max-w-prose text-sm text-strong'>
            Happiness is a <span className='font-medium'>R 100 boost</span> 🚀
            on your first investment contribution, plus a chance to{' '}
            <span className='font-medium'>win R 5000</span> just for signing up.
            Read the{' '}
            <Router
              href={route('marketing.competition-rules')}
              className='font-medium text-primary'
            >
              competition rules
            </Router>{' '}
            for more details.
          </p>
        </div>
      )}
      <MarketingHeader />
      {children}
      <footer className='w-full bg-black'>
        <div className='mx-auto flex w-full max-w-5xl flex-col items-start gap-10 p-10 py-20 text-white'>
          <FynbosLogo />
          <div className='grid w-full grid-cols-3 gap-10'>
            <div className='col-span-full flex flex-col gap-2 text-xl sm:col-span-1'>
              <h3 className='mb-4 font-medium'>Company</h3>
              <AnchorRouter
                href='#pricing'
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Pricing
              </AnchorRouter>
              <Router
                href={route('learn')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Learn
              </Router>
              <Router
                href={route('login')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Start
              </Router>
            </div>
            <div className='col-span-full flex flex-col gap-2 text-xl sm:col-span-1'>
              <h3 className='mb-4 font-medium'>Legal</h3>
              <Router
                href={route('marketing.terms-and-conditions')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Terms & Conditions
              </Router>
              <Router
                href={route('marketing.privacy-policy')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Privacy Policy
              </Router>
              <Router
                href={route('marketing.fais-disclosures')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                FAIS Disclosures
              </Router>
              <AnchorRouter
                target='_blank'
                href='https://cdn.fynbos.app/pdfs/fynbos-PAIA-manual-October-2024.pdf'
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                PAIA Manual
              </AnchorRouter>
              <Router
                href={route('marketing.fica-policy')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                FICA Policy
              </Router>
              <Router
                href={route('marketing.complaints-policy')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Complaints Policy
              </Router>
            </div>
            <div className='col-span-full flex flex-col gap-2 text-xl sm:col-span-1'>
              <h3 className='mb-4 font-medium'>Social</h3>
              <div className='flex gap-6'>
                <AnchorRouter
                  target='_blank'
                  href='https://www.linkedin.com/company/fynbos'
                >
                  <LinkedInIcon className='text-white' />
                </AnchorRouter>
                <AnchorRouter
                  target='_blank'
                  href='https://twitter.com/fynbosmoney'
                >
                  <XIcon className='text-white' />
                </AnchorRouter>
                <AnchorRouter
                  target='_blank'
                  href='https://facebook.com/fynbosmoney'
                >
                  <FaceBookIcon className='text-white' />
                </AnchorRouter>
                <AnchorRouter
                  target='_blank'
                  href='https://instagram.com/fynbosmoney'
                >
                  <InstagramIcon className='text-white' />
                </AnchorRouter>
              </div>
            </div>
          </div>

          <span className='text-sm text-disabled'>
            &copy; {new Date().getFullYear()} Fynbos Technologies Ltd.
          </span>
        </div>
      </footer>
      <MetaPixel />
      <GoogleTag />
    </div>
  )
}
